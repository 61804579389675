<script>
    import { metaInfo } from "@/core/MetaInfo";

    export default {
        name: "ErrorPage",
        metaInfo() {
            return metaInfo.getErrorMeta();
        },
    };
</script>

<template>
    <div class="l-container">
        <div class="b-404">
            <div class="b-404__title">404 ошибка</div>
            <p class="b-404__text">
                К сожалению, этой страницы не существует. Перейти на
                <router-link class="b-404__link" :to="{ name: 'Home' }"> главную </router-link>
            </p>
        </div>
    </div>
</template>

<style lang="scss">
    .b-404 {
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include bp($bp-desktop-sm) {
            padding: 174px 0;
        }
    }

    .b-404__title {
        font-size: 34px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 30px;
    }

    .b-404__text {
        font-size: 18px;
        line-height: 24px;
    }

    .b-404__link {
        color: var(--primary);
    }
</style>
